import { apiSlice } from '@/API/api.slice';
import { Endpoints } from '@/constants/api';
import Tally from '@/types/tally.types';

export const talliesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTallies: builder.query<Tally[], number[]>({
      query: (templateList) => `${Endpoints.Tally}?template_list=${templateList.join('~')}`,
      transformResponse:(response:Tally[])=>{
        return response.map((tally)=>{
          return {
            ...tally,
            id:tally.tally_id,
            name:tally.tally_name,
          }
      })}
    }),
  }),
});

export const { useGetTalliesQuery } = talliesApi;
