/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Skeleton,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { CellContext, createColumnHelper, SortingState } from '@tanstack/react-table';
import { produce } from 'immer';
import _ from 'lodash';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCheckCircle, FaUserCheck } from 'react-icons/fa';

import {
  useGetPersonnelBySearchQuery,
  useGetPersonnelForViewAccessQuery,
  useGetPersonnelTypesQuery,
} from '@/API/personnel.api';
import {
  useDeletePublicViewUrlMutation,
  useGeneratePublicViewUrlMutation,
  useUpdateViewMutation,
} from '@/API/views.api';
import ControlledSearch from '@/components/controlled-search/ControlledSearch';
import { FilterSelectValue } from '@/components/filter-select/FilterSelect';
import PaginatedTableControlled from '@/components/paginated-table-controlled/PaginatedTableControlled';
import PersonnelTypeDropdown from '@/components/personnel-types-dropdown/PersonnelTypeDropdown';
import PaginatedTableSkeleton from '@/components/skeletons/paginated-table-skeleton/PaginatedTableSkeleton';
import UIConfig from '@/config/ui.config';
import APIConstants from '@/constants/api';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_TABLE_SIZE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_UPDATE_SUCCESS_MESSAGE,
  paginationDefault,
  ToastTypes,
} from '@/constants/defaults';
import { ApiError } from '@/types/api.types';
import { DepartmentSlim } from '@/types/department.types';
import { PersonnelItemSlim } from '@/types/personnel.types';
import View from '@/types/view.types';
import SortingUtils from '@/utils/sorting';
import { getLBPublicViewUrl } from '@/utils/url';

import DepartmentsDropdown from '../departments-dropdown/DepartmentsDropdown';

interface ViewAccessDrawerProps {
  departments: DepartmentSlim[];
  handleClose: () => void;
  isLoading: boolean;
  isOpen: boolean;
  view: View;
}

interface PersonnelTableItem extends Partial<PersonnelItemSlim> {
  displayName: string;
}

type PersonnelListItemCollection = { [key: number]: PersonnelItemSlim };

const PUBLIC_URL_COPY_SUCCESS_MESSAGE = 'Public URL copied successfully.';
const PUBLIC_URL_COPY_ERROR_MESSAGE = 'An error occurred when trying to copy Public URL.';
const PUBLIC_URL_GENERATE_SUCCESS_MESSAGE = 'Public URL generated successfully.';
const PUBLIC_URL_GENERATE_ERROR_MESSAGE = 'An error occurred when trying to generate Public URL.';
const PUBLIC_URL_DELETED_SUCCESS_MESSAGE = 'Public URL deleted successfully.';
const PUBLIC_URL_DELETED_ERROR_MESSAGE = 'Public URL could not be deleted.';

const getSelectedPersonnelIds = (personnel: PersonnelListItemCollection): number[] => {
  const selectedPersonnelIds = [];

  for (const [key, value] of Object.entries(personnel)) {
    if (value.isSelected) {
      selectedPersonnelIds.push(Number(key));
    }
  }

  return selectedPersonnelIds;
};

const ViewAccessDrawer = (props: ViewAccessDrawerProps): React.JSX.Element => {
  const { departments, handleClose, isLoading, isOpen, view } = props;
  const { viewId, name: viewName, publicViewId } = view;

  const {
    isOpen: isConfirmDiscardChangesOpen,
    onOpen: openConfirmDiscardChanges,
    onClose: closeConfirmDiscardChanges,
  } = useDisclosure();
  const cancelDiscardChangesRef = React.useRef(null);
  const [userHasConfirmedDiscardChanges, setUserHasConfirmedDiscardChanges] = React.useState<boolean>(true);

  const [personnelSearchFilter, setPersonnelSearchFilter] = React.useState<string>('');
  const [personnelSearchValue, setPersonnelSearchValue] = React.useState<string>('');
  const [personnelData, setPersonnelData] = React.useState<PersonnelListItemCollection>({});
  const [sortingState, setSortingState] = React.useState<SortingState>([{ desc: false, id: 'displayName' }]);

  const {
    data: currentViewAccessPersonnel = [],
    isLoading: personnelDataIsLoading,
    isFetching: personnelDataIsFetching,
  } = useGetPersonnelForViewAccessQuery(view?.accessibleBy ?? [], {
    skip: !view || !view?.accessibleBy?.length || !isOpen,
  });

  const [selectedDepartmentIds, setSelectedDepartmentIds] = React.useState<number[]>([]);
  const [departmentListIsClosed, setDepartmentListIsClosed] = React.useState<boolean>(true);
  const [personnelTypeListIsClosed, setPersonnelTypeListIsClosed] = React.useState<boolean>(true);

  const {
    data: departmentPersonnelData,
    isLoading: departmentPersonnelDataIsLoading,
    isFetching: departmentPersonnelDataIsFetching,
  } = useGetPersonnelBySearchQuery(
    {
      DepartmentIds: selectedDepartmentIds ?? [],
      OrderByLastName: 'desc',
      SearchQuery: personnelSearchFilter ? `display_name=${personnelSearchFilter}` : undefined,
    },
    { skip: (!selectedDepartmentIds.length && !personnelSearchFilter) || !isOpen || !departmentListIsClosed },
  );

  const {
    data: personnelTypes,
    isLoading: personnelTypesIsLoading,
    isFetching: personnelTypesIsFetching,
  } = useGetPersonnelTypesQuery(undefined, { skip: !isOpen || !personnelTypeListIsClosed });

  const [generatePublicURL, generatePublicURLResponse] = useGeneratePublicViewUrlMutation();
  const [deletePublicURL, deletePublicURLResponse] = useDeletePublicViewUrlMutation();
  const [updateView, updateViewResponse] = useUpdateViewMutation();

  const [isSelectAllChecked, setIsSelectAllChecked] = React.useState<boolean>(false);
  const [showSelectedItemsOnly, setShowSelectedItemsOnly] = React.useState<boolean>(false);
  const [availablePersonnelTypes, setAvailablePersonnelTypes] = React.useState<FilterSelectValue[]>([]);
  const [selectedPersonnelTypeIds, setSelectedPersonnelTypeIds] = React.useState<number[]>([]);
  const [tablePersonnelData, setTablePersonnelData] = React.useState<PersonnelTableItem[]>([]);
  const [pagination, setPagination] = React.useState({
    PageIndex: 0,
    PageNumber: 1,
    PageSize: paginationDefault.PageSize,
    TotalPages: 1,
    TotalRecords: 0,
  });

  const toast = useToast();

  // If no departments are selected and no search filter is applied, show only personnel that are selected
  React.useEffect(() => {
    if (!selectedDepartmentIds.length && !personnelSearchFilter) {
      const selectedPersonnel = produce(personnelData, (draft) => {
        for (const [key, value] of Object.entries(draft)) {
          if (!value.isSelected) {
            delete draft[Number(key)];
          }
        }
      });

      setPersonnelData(selectedPersonnel);
    }
  }, [selectedDepartmentIds.length, personnelSearchFilter, personnelData]);

  // Happens when department personnel data is fetched
  React.useEffect(() => {
    if (!departmentPersonnelDataIsLoading && !departmentPersonnelDataIsFetching && selectedDepartmentIds.length) {
      // filter unselected personnel from personnelData
      const selectedPersonnel = produce(personnelData, (draft) => {
        for (const [key, value] of Object.entries(draft)) {
          if (!value.isSelected) {
            delete draft[Number(key)];
          }
        }
      });

      // construct seenPersonnelIds from filtered selected personnel and LBS_USER_IDS
      const seenPersonnelIds: Set<number> = new Set([
        ...Object.keys(selectedPersonnel).map((k) => Number(k)),
        ...APIConstants.LBS_USER_IDS,
      ]);

      // add personnel from departmentPersonnelData to fetchedPersonnel if not in seenPersonnelIds
      const fetchedPersonnel: PersonnelListItemCollection = {};
      departmentPersonnelData?.forEach((personnel) => {
        if (seenPersonnelIds.has(personnel.id)) return;

        seenPersonnelIds.add(personnel.id);
        fetchedPersonnel[personnel.id] = personnel;
      });

      setPersonnelData({ ...selectedPersonnel, ...fetchedPersonnel });
    }
  }, [departmentPersonnelData, departmentListIsClosed, departmentPersonnelDataIsLoading, departmentPersonnelDataIsFetching, selectedDepartmentIds.length, personnelData]);

  React.useEffect(() => {
    if (!personnelDataIsLoading && !personnelDataIsFetching) {
      const currentPersonnelData = { ...personnelData };

      if (!currentViewAccessPersonnel?.length) return;

      currentViewAccessPersonnel?.forEach((personnel) => {
        if (APIConstants.LBS_USER_IDS.includes(personnel.id)) return;
        currentPersonnelData[personnel.id] = personnel;
      });

      setPersonnelData((prevState) => {
        // eslint-disable-next-line no-magic-numbers
        if (Object.keys(prevState).length === 0 && currentViewAccessPersonnel.length === 0) {
          return prevState;
        }

        return currentPersonnelData;
      });
    }
  }, [currentViewAccessPersonnel, personnelData, personnelDataIsFetching, personnelDataIsLoading]);

  // When personnel data changes, we need to ensure the personnel types custom-dropdown is populated with the correct values
  React.useEffect(() => {
    const seenPersonnelTypeIds: Set<number> = new Set();

    for (const [_key, value] of Object.entries(personnelData)) {
      if (seenPersonnelTypeIds.has(value.pTypeId)) continue;

      seenPersonnelTypeIds.add(value.pTypeId);
    }

    const availablePersonnelTypeIds = Array.from(seenPersonnelTypeIds);

    const availablePersonnelTypeItems: FilterSelectValue[] =
      personnelTypes
        ?.filter((personnelType) => {
          return availablePersonnelTypeIds.includes(personnelType.ptypeId);
        })
        ?.map((personnelType) => {
          return {
            label: personnelType.name,
            value: personnelType.ptypeId,
          };
        }) ?? [];

    setAvailablePersonnelTypes(availablePersonnelTypeItems);
  }, [personnelData, personnelTypes]);

  React.useEffect(() => {
    setPagination((prevPagination) => {
      return {
        ...prevPagination,
        PageIndex: 0,
        PageNumber: 1,
      };
    });
  }, [showSelectedItemsOnly]);

  const numberOfSelectedPersonnel = React.useMemo(() => {
    // eslint-disable-next-line no-magic-numbers
    return tablePersonnelData.reduce((prev, curr) => (curr.isSelected ? prev + 1 : prev), 0);
  }, [tablePersonnelData]);

  const haveChangesBeenMade = React.useMemo(() => {
    if (!view || !view?.accessibleBy) return false;

    const { accessibleBy: originalSelectedPersonnelIds } = view;

    const originalNonLbsUserIds = originalSelectedPersonnelIds.filter((id) => !APIConstants.LBS_USER_IDS.includes(id));

    // If the two collections are different lengths, then they are different and we should submit
    if (numberOfSelectedPersonnel !== originalNonLbsUserIds.length) {
      return true;
    }
    // If they are the same length, then we need to check if they are the same
    else if (originalNonLbsUserIds.length === numberOfSelectedPersonnel) {
      // Check if they are the same length with same values, disregarding order
      const selectedPersonnelIds = getSelectedPersonnelIds(personnelData);

      const areSame = selectedPersonnelIds.every((id) => originalNonLbsUserIds.includes(id));

      // If they are not the same, then we should submit
      if (!areSame) {
        return true;
      }
    }

    return false;
  }, [view, numberOfSelectedPersonnel, personnelData]);

  const closeHandler = React.useCallback((discardConfirmed = false) => {
    if (haveChangesBeenMade && !discardConfirmed) {
      openConfirmDiscardChanges();

      return;
    }
    setPersonnelData({});
    setTablePersonnelData([]);
    setIsSelectAllChecked(false);
    setShowSelectedItemsOnly(false);
    setDepartmentListIsClosed(true);
    setPersonnelSearchFilter('');
    setPersonnelSearchValue('');
    setSelectedDepartmentIds([]);
    setSelectedPersonnelTypeIds([]);
    setPagination({
      PageIndex: 0,
      PageNumber: 1,
      PageSize: paginationDefault.PageSize,
      TotalPages: 1,
      TotalRecords: 0,
    });

    handleClose();
  }, [handleClose, haveChangesBeenMade, openConfirmDiscardChanges]);

  const confirmDiscardChangesAndClose = () => {
    closeConfirmDiscardChanges();
    closeHandler(true);
  };
  const getConfirmDiscardChangesDialog = () => (
    <AlertDialog
      isOpen={isConfirmDiscardChangesOpen}
      leastDestructiveRef={cancelDiscardChangesRef}
      onClose={closeConfirmDiscardChanges}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {`${viewName} Access`}
          </AlertDialogHeader>

          <AlertDialogBody>Discard changes and close?</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelDiscardChangesRef} onClick={closeConfirmDiscardChanges}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={confirmDiscardChangesAndClose} ml={3}>
              Discard
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );

  const personnelTableItemFactory = React.useCallback((personnelList: PersonnelListItemCollection): PersonnelTableItem[] => {
    const personnelData: PersonnelTableItem[] = [];
    const seenPersonnelIds = new Set();

    for (const [key, value] of Object.entries(personnelList)) {
      if (APIConstants.LBS_USER_IDS.includes(Number(key)) || seenPersonnelIds.has(Number(key))) continue;

      if (showSelectedItemsOnly && !value.isSelected) continue;

      const personnelItem = {
        ...value,
        displayName: value.personnelName.display,
      };

      if (selectedPersonnelTypeIds.length) {
        if (value?.pTypeId && selectedPersonnelTypeIds.includes(value.pTypeId)) {
          personnelData.push(personnelItem);
        }
      } else {
        personnelData.push(personnelItem);
      }

      seenPersonnelIds.add(key);
    }

    return personnelData;
  }, [selectedPersonnelTypeIds, showSelectedItemsOnly]);

  const personnelClickHandler = (personnelId: number) => {
    setPersonnelData((prevState) =>
      produce(prevState, (draft) => {
        draft[personnelId].isSelected = !draft[personnelId].isSelected;
      }),
    );
  };

  React.useEffect(() => {
    setTablePersonnelData(personnelTableItemFactory(personnelData));
  }, [personnelTableItemFactory, personnelData]);

  React.useEffect(() => {
    setPagination((prevState) => {
      return {
        ...prevState,
        TotalPages: Math.ceil(tablePersonnelData.length / prevState.PageSize),
        TotalRecords: tablePersonnelData.length,
      };
    });
  }, [tablePersonnelData]);

  const handleSelectAll = React.useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (isSelectAllChecked) {
      setPersonnelData(
        produce((draft) => {
          for (const [_key, value] of Object.entries(draft)) {
            value.isSelected = false;
          }
        }),
      );

      setIsSelectAllChecked(false);

      return;
    }

    setPersonnelData(
      produce((draft) => {
        for (const value of Object.values(draft)) {
          value.isSelected = true;
        }
      }),
    );

    setIsSelectAllChecked(true);
  }, [isSelectAllChecked]);

  React.useEffect(() => {
    if (!numberOfSelectedPersonnel) {
      setShowSelectedItemsOnly(false);
    }
  }, [numberOfSelectedPersonnel]);

  const handleToggleOnlySelected = React.useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!Object.entries(tablePersonnelData) || !numberOfSelectedPersonnel) return;
    setShowSelectedItemsOnly((prevState) => !prevState);
  }, [numberOfSelectedPersonnel, tablePersonnelData]);

  const personnelColumnHeader = React.useMemo(() => (
    <VStack>
      {/* eslint-disable-next-line no-magic-numbers */}
      {tablePersonnelData?.length ?? 0 > 0 ? (
        <HStack gap={2}>
          <Button colorScheme={'blue'} size={'xs'} onClick={handleSelectAll}>
            {/* eslint-disable-next-line no-magic-numbers */}
            {isSelectAllChecked && numberOfSelectedPersonnel === Object.entries(personnelData).length
              ? 'Deselect All'
              : 'Select All'}
          </Button>
          <Button size={'xs'} onClick={handleToggleOnlySelected}>
            <FaUserCheck size={'20px'} color={showSelectedItemsOnly ? 'green' : 'rgba(0,0,0,0.1)'} />
          </Button>
          <Box>{`${numberOfSelectedPersonnel} personnel selected`}</Box>
        </HStack>
      ) : (
        <Box>Select Department(s) to Populate Personnel</Box>
      )}
    </VStack>
  ), [handleSelectAll, handleToggleOnlySelected, isSelectAllChecked, numberOfSelectedPersonnel, personnelData, showSelectedItemsOnly, tablePersonnelData?.length]);

  const getCell = (cell: CellContext<PersonnelTableItem, string>, onClick: (personnelId: number) => void) => {
    return (
      <Box h={'100%'} maxW={`${DEFAULT_TABLE_SIZE}px`} w={`${DEFAULT_TABLE_SIZE}px`} textOverflow={'ellipsis'}>
        <HStack
          justifyContent={'space-between'}
          h={'100%'}
          padding={'10px'}
          onClick={() => onClick(Number(cell.row.original.id))}
        >
          <Text noOfLines={1}>{cell.getValue()}</Text>
          {cell.row.original.isSelected && <FaCheckCircle color={'green'} />}
        </HStack>
      </Box>
    );
  };

  const columnHelper = createColumnHelper<PersonnelTableItem>();

  const tableHeader = React.useMemo(() => {
    return (
      <HStack justifyContent={'space-between'} maxW={'430px'} w={'430px'} padding={'0px'}>
        {personnelColumnHeader}
        <Text
          onClick={() => {
            const currentSortingState = SortingUtils.getSortingStateById(sortingState, 'displayName');
            const updatedSortingState = SortingUtils.sortTFU(currentSortingState?.desc);
            setSortingState(SortingUtils.setSortingStateById(sortingState, 'displayName', updatedSortingState));
          }}
          w={'70px'}
          padding={'5px'}
          marginRight={'-30px'}
          cursor={'pointer'}
          border={'1px solid #2768a5'}
          backgroundColor={'blue.200'}
          borderRadius={'5px'}
          color={'white'}
        >
          Sort
        </Text>
      </HStack>
    );
  }, [personnelColumnHeader, sortingState]);

  const columns = [
    columnHelper.accessor(({ displayName }) => displayName, {
      cell: (cell) => getCell(cell, personnelClickHandler),
      header: () => tableHeader,
      id: 'displayName',
    }),
  ];

  const handleSubmit = React.useCallback(async () => {
    if (!view) return;

    const { viewId, name, filterId, themeId } = view;

    try {
      if (haveChangesBeenMade) {
        await updateView({
          accessibleBy: getSelectedPersonnelIds(personnelData),
          filterId,
          name,
          themeId,
          viewId,
        }).unwrap();
      }

      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.SUCCESS,
        title: DEFAULT_UPDATE_SUCCESS_MESSAGE,
      });
    } catch (error) {
      // Cast unknown error type to ApiError
      const apiError = error as ApiError;
      const message = apiError?.data?.Message ?? DEFAULT_ERROR_MESSAGE;

      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: message,
      });
    } finally {
      closeHandler(true);
    }
  }, [view, haveChangesBeenMade, toast, updateView, personnelData, closeHandler]);

  const handlePersonnelTypeChange = (personnelTypeIds: number[]) => {
    setSelectedPersonnelTypeIds(personnelTypeIds);
  };

  const handleGeneratePublicURL = async () => {
    try {
      const response = await generatePublicURL({ name: viewName, viewId }).unwrap();

      // Check if the the public view was generated successfully
      if (response?.id) {
        toast({
          duration: DEFAULT_TOAST_DURATION,
          isClosable: true,
          position: 'top',
          status: ToastTypes.SUCCESS,
          title: PUBLIC_URL_GENERATE_SUCCESS_MESSAGE,
        });
      }
    } catch (error) {
      // Cast unknown error type to ApiError
      const apiError = error as ApiError;
      const message = apiError?.data?.Message ?? PUBLIC_URL_GENERATE_ERROR_MESSAGE;

      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: message,
      });
    }
  };

  const handleDeletePublicURL = async () => {
    if (!publicViewId) return;

    try {
      const response = await deletePublicURL(publicViewId).unwrap();

      // Check if the public url was deleted successfully
      if (response?.deleted) {
        toast({
          duration: DEFAULT_TOAST_DURATION,
          isClosable: true,
          position: 'top',
          status: ToastTypes.SUCCESS,
          title: PUBLIC_URL_DELETED_SUCCESS_MESSAGE,
        });
      }
    } catch (error) {
      // Cast unknown error type to ApiError
      const apiError = error as ApiError;
      const message = apiError?.data?.Message ?? PUBLIC_URL_DELETED_ERROR_MESSAGE;

      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: message,
      });
    }
  };

  const handleGoToPageNumber = (pageNumber: number) => {
    setPagination((prevState) => {
      return {
        ...prevState,
        // eslint-disable-next-line no-magic-numbers
        PageIndex: pageNumber - 1 < 0 ? 0 : pageNumber - 1,
        PageNumber: pageNumber > prevState.TotalPages ? prevState.TotalPages : pageNumber,
      };
    });
  };

  const handleSetPageSize = (pageSize: number) => {
    setPagination((prevState) => {
      return {
        ...prevState,
        PageIndex: 0,
        PageNumber: 1,
        PageSize: pageSize,
        TotalPages: Math.ceil(tablePersonnelData.length / pageSize),
      };
    });
  };

  const handleCopyPublicUrl = (text: string, result: boolean) => {
    if (text && result) {
      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.INFO,
        title: PUBLIC_URL_COPY_SUCCESS_MESSAGE,
      });
    } else {
      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: PUBLIC_URL_COPY_ERROR_MESSAGE,
      });
    }
  };

  const isDataLoading =
    isLoading ||
    departmentPersonnelDataIsLoading ||
    personnelDataIsLoading ||
    departmentPersonnelDataIsFetching ||
    personnelTypesIsLoading ||
    personnelTypesIsFetching ||
    personnelDataIsFetching;

  const getPublicURLControls = (): React.JSX.Element => {
    if (!publicViewId) {
      return (
        <Button
          colorScheme={'blue'}
          isDisabled={isDataLoading}
          isLoading={generatePublicURLResponse.isLoading}
          onClick={handleGeneratePublicURL}
          size={'sm'}
        >
          Generate URL
        </Button>
      );
    }

    const publicViewURL = getLBPublicViewUrl(publicViewId);

    return (
      <HStack>
        {publicViewURL && (
          <CopyToClipboard text={publicViewURL} onCopy={handleCopyPublicUrl}>
            <Button colorScheme={'blue'} isDisabled={isDataLoading} size={'sm'}>
              Copy
            </Button>
          </CopyToClipboard>
        )}
        <Button
          colorScheme={'red'}
          isLoading={deletePublicURLResponse.isLoading}
          isDisabled={isDataLoading}
          onClick={handleDeletePublicURL}
          size={'sm'}
        >
          Delete
        </Button>
      </HStack>
    );
  };

  // ToDo: fix this
  const debouncedSearch = React.useCallback(
    _.debounce((value: string) => {
      setPersonnelSearchFilter(value);
      handleGoToPageNumber(paginationDefault.PageNumber);
    }, UIConfig.DEFAULT_DEBOUNCE_TIME_MS),
    [],
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPersonnelSearchValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  const getInfoText = (): React.JSX.Element => {
    // If no data exists in the table and the user has not selected any personnel
    if (!tablePersonnelData.length && !numberOfSelectedPersonnel && departmentListIsClosed) {
      // If the user has not selected any departments
      if (!selectedDepartmentIds.length && !personnelSearchValue) {
        return <Text>Select one or more departments or search for personnel by name</Text>;
      }
      if (selectedDepartmentIds.length || personnelSearchValue.length) {
        return <Text>No personnel found which match the provided filters</Text>;
      }
    }

    return <></>;
  };

  const getPersonnelTable = (): React.JSX.Element => {
    return (
      <VStack gap={'3'}>
        <HStack>
          {isDataLoading ? (
            <VStack h={'70px'} w={'250px'} align={'start'}>
              <Text align={'left'}>Departments</Text>
              <Skeleton h={'38px'} w={'100%'} startColor="blue.300" endColor="blue.500" />
            </VStack>
          ) : (
            <DepartmentsDropdown
              departmentChangeHandler={setSelectedDepartmentIds}
              departmentList={departments}
              departmentListCloseHandler={() => setDepartmentListIsClosed(true)}
              departmentListOpenHandler={() => setDepartmentListIsClosed(false)}
              isLoading={isDataLoading}
              selectedIds={selectedDepartmentIds}
            />
          )}
          {isDataLoading ? (
            <VStack h={'70px'} w={'250px'} align={'start'}>
              <Text align={'left'}>Personnel Types</Text>
              <Skeleton h={'38px'} w={'100%'} startColor="blue.300" endColor="blue.500" />
            </VStack>
          ) : (
            <PersonnelTypeDropdown
              availablePersonnelTypes={availablePersonnelTypes}
              isLoading={isDataLoading}
              onChangeHandler={handlePersonnelTypeChange}
              personnelTypeListCloseHandler={() => setPersonnelTypeListIsClosed(true)}
              personnelTypeListOpenHandler={() => setPersonnelTypeListIsClosed(false)}
              selectedPersonnelTypeIds={selectedPersonnelTypeIds}
            />
          )}
        </HStack>
        <HStack gap={'60px'}>
          <VStack w={'136px'}>
            <span>Public URL</span>
            {getPublicURLControls()}
          </VStack>
          <Box h={'64px'} alignItems={'center'} paddingTop={'24px'}>
            <ControlledSearch
              isDisabled={isDataLoading}
              onChange={handleSearch}
              placeholder={'Search Personnel'}
              value={personnelSearchValue}
            />
          </Box>
        </HStack>
        {isDataLoading ? (
          <Box>
            <PaginatedTableSkeleton />
          </Box>
        ) : (
          <Box>
            {!tablePersonnelData.length ? (
              <Box textAlign={'center'}> {getInfoText()} </Box>
            ) : (
              <PaginatedTableControlled
                columns={columns}
                data={tablePersonnelData}
                filters={[{ id: 'displayName', value: personnelSearchFilter }]}
                isLoading={false}
                noRowPadding={true}
                onGoToPage={handleGoToPageNumber}
                onNextPage={handleGoToPageNumber}
                onPreviousPage={handleGoToPageNumber}
                onSetPageSize={handleSetPageSize}
                pagination={pagination}
                sortingState={sortingState}
              />
            )}
          </Box>
        )}
      </VStack>
    );
  };

  const getLoadingSkeleton = (): React.JSX.Element => {
    return (
      <>
        <DrawerHeader borderBottomWidth={'1px'}>
          <Skeleton h={'30px'} w={'200px'} startColor="blue.300" endColor="blue.500" />
        </DrawerHeader>
        <DrawerBody>{getPersonnelTable()}</DrawerBody>
        <DrawerFooter>
          <HStack>
            <Button colorScheme={'blue'} isDisabled={true}>
              Submit
            </Button>
            <Button colorScheme={'blue'} variant={'outline'} onClick={() => closeHandler()}>
              Cancel
            </Button>
          </HStack>
        </DrawerFooter>
      </>
    );
  };

  return (
    <Drawer isOpen={isOpen} onClose={closeHandler} placement={'right'} size={'lg'} returnFocusOnClose={false}>
      <DrawerOverlay />
      <DrawerContent>
        {getConfirmDiscardChangesDialog()}
        <DrawerCloseButton />
        {isDataLoading ? (
          getLoadingSkeleton()
        ) : (
          <>
            <DrawerHeader borderBottomWidth={'1px'}>{`${viewName} Access`}</DrawerHeader>
            <DrawerBody>{getPersonnelTable()}</DrawerBody>
            <DrawerFooter>
              <HStack>
                <Button
                  colorScheme={'blue'}
                  onClick={handleSubmit}
                  isLoading={updateViewResponse.isLoading}
                  spinner={<Spinner />}
                >
                  Submit
                </Button>
                <Button colorScheme={'blue'} variant={'outline'} onClick={() => closeHandler()}>
                  Cancel
                </Button>
              </HStack>
            </DrawerFooter>
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default ViewAccessDrawer;
