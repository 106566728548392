import { Box, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingFn,
  SortingState,
  Table,
  VisibilityState,
} from '@tanstack/react-table';
import { produce, WritableDraft } from 'immer';
import React, { ReactNode } from 'react';
import { useImmerReducer } from 'use-immer';

import ColorPickerDialog from '@/components/color-picker-dialog/ColorPickerDialog';
import PaginatedTable from '@/components/paginated-table/PaginatedTable';
import {
  DEFAULT_ARRAY_START_INDEX,
  DEFAULT_ONE_ELEMENT_ARRAY_LENGTH,
  DEFAULT_TABLE_WIDTH_MD,
} from '@/constants/defaults';
import { APLItem, CellColor, ColorCell, ColorMode, ColorPickerState, HeaderSortHandlers } from '@/types/ui.types';

export type CellColors = Omit<ColorPickerState, 'colorMode' | 'headerText' | 'isOpen'>;

interface PaginatedPreviewTableProps<T> {
  availableItemsTableColumnFilters: ColumnFiltersState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  availableItemsTableColumns: ColumnDef<any, any>[];
  availableItemsTableData: unknown[];
  availableRowUniqueKey: string;
  isLoading: boolean;
  previewRowUniqueKey: string;
  previewTableColumnFilters: ColumnFiltersState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previewTableColumns: ColumnDef<any, any>[];
  previewTableData: unknown[];
  availableItemsTableColumnVisibility?: VisibilityState;
  availableItemsTableHeader?: ReactNode;
  availableItemsTableHeaderButton?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  availableItemsTableInstanceRef?: React.MutableRefObject<Table<any> | undefined>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previewItemsTableInstanceRef?: React.MutableRefObject<Table<any> | undefined>;
  availableItemsTableSortingState?: SortingState;
  headerSortHandlers?: HeaderSortHandlers;
  hideSortingTable?: boolean;
  handleSetCellColors?: (colors: CellColors) => void;
  handleResetSorting?: () => void;
  loadingMessage?: string;
  overrideTableSorting?: boolean;
  previewHeaderSortHandlers?: HeaderSortHandlers;
  previewRowsAreDraggable?: boolean;
  previewTableColumnVisibility?: VisibilityState;
  previewTableDragHandler?: (updatedData: unknown[]) => void;
  previewTableHeader?: ReactNode;
  previewTableHeaderButton?: ReactNode;
  previewTableNoDataFoundMessage?: string;
  previewTableSortingState?: SortingState;
  previewTableUniqueSelectedIds?: Set<number | string>;
  showColorPicker?: boolean;
  showSideControls?: boolean;
  selectedPreviewItems?: APLItem<T>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortingFns?: Record<string, SortingFn<any>>;
}

const DEFAULT_COLOR_PICKER_STATE: ColorPickerState = {
  cellColor: '#ffffff',
  colorMode: ColorMode.Cell,
  headerText: '',
  isOpen: false,
  textColor: '#ffffff',
};

interface UIState {
  colorPickerState: ColorPickerState;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ITableState<T> {
  uiState: UIState;
}

enum ReducerActionTypes {
  SET_COLOR_PICKER_IS_OPEN,
  SET_COLOR_PICKER_STATE,
}

type ReducerAction = { type: ReducerActionTypes, payload?: unknown };

const stateReducer = <T, >(draft: WritableDraft<ITableState<T>>, action: ReducerAction): void => {
  switch (action.type) {
    case ReducerActionTypes.SET_COLOR_PICKER_IS_OPEN:
      draft.uiState.colorPickerState.isOpen = action.payload as boolean;
      break;
    case ReducerActionTypes.SET_COLOR_PICKER_STATE:
      draft.uiState.colorPickerState = action.payload as ColorPickerState;
      break;
    default:
      break;
  }
};

// Type guard for ColorCell[]
const isColorCellArray = (item: unknown): item is ColorCell[] => {
  const itemAsColorCellArray = item as ColorCell[];

  return Array.isArray(itemAsColorCellArray) && itemAsColorCellArray.every((target) => {
    const targetAsColorCell = target as ColorCell;

    return (
      typeof targetAsColorCell === 'object' &&
      targetAsColorCell !== null &&
      (typeof targetAsColorCell?.color === 'string' || targetAsColorCell?.color === null) &&
      (typeof targetAsColorCell?.colorText === 'string' || targetAsColorCell?.colorText === null));
  });
};

const PaginatedPreviewTable = <T, >(props: PaginatedPreviewTableProps<T>): React.JSX.Element => {
  const {
    availableItemsTableColumnFilters,
    availableItemsTableColumns,
    availableItemsTableData,
    availableRowUniqueKey,
    isLoading,
    previewTableColumnFilters,
    previewTableColumns,
    previewTableData,
    availableItemsTableColumnVisibility = undefined,
    availableItemsTableHeader = null,
    availableItemsTableHeaderButton = null,
    availableItemsTableInstanceRef = undefined,
    previewItemsTableInstanceRef = undefined,
    availableItemsTableSortingState,
    headerSortHandlers,
    hideSortingTable,
    handleSetCellColors = () => void {},
    // ToDo: Why is this function not used?
    handleResetSorting = () => void {},
    loadingMessage = 'Loading...',
    overrideTableSorting = false,
    previewHeaderSortHandlers,
    previewRowsAreDraggable = false,
    previewRowUniqueKey,
    previewTableColumnVisibility = undefined,
    previewTableDragHandler = undefined,
    previewTableHeader = null,
    previewTableHeaderButton = null,
    previewTableNoDataFoundMessage = undefined,
    previewTableSortingState,
    previewTableUniqueSelectedIds = new Set<number>(),
    selectedPreviewItems = undefined,
    showColorPicker = true,
    showSideControls = true,
    sortingFns = undefined,
  } = props;


  const [tableState, dispatchTableState] = useImmerReducer<ITableState<T>, ReducerAction>(stateReducer, {
    uiState: {
      colorPickerState: DEFAULT_COLOR_PICKER_STATE,
    },
  });

  // This is needed to display the correct colors and header text when opening Color Picker Dialog
  const handleColorPicker = React.useCallback(() => {
    console.log('Selected Preview Items: ', selectedPreviewItems);
    if (selectedPreviewItems?.length && isColorCellArray(selectedPreviewItems)) {
      // If the user selects just 1 item, set the colors and the name of that item on the Color Picker Dialog
      if (selectedPreviewItems.length === DEFAULT_ONE_ELEMENT_ARRAY_LENGTH) {
        const selectedItemColors: CellColor = {
          cellColor: selectedPreviewItems[DEFAULT_ARRAY_START_INDEX].color ?? DEFAULT_COLOR_PICKER_STATE.cellColor,
          textColor: selectedPreviewItems[DEFAULT_ARRAY_START_INDEX].colorText ?? DEFAULT_COLOR_PICKER_STATE.textColor,
        };
        const headerText = selectedPreviewItems[DEFAULT_ARRAY_START_INDEX].name;

        dispatchTableState({
          payload: produce(tableState.uiState.colorPickerState, (draft) => {
            draft.cellColor = selectedItemColors.cellColor;
            draft.textColor = selectedItemColors.textColor;
            draft.headerText = headerText;
            draft.isOpen = true;
          }),
          type: ReducerActionTypes.SET_COLOR_PICKER_STATE,
        });
      } else {
        // Display the selected item name or the number of the selected items on Color Picker Dialog header
        dispatchTableState({
          payload: produce(tableState.uiState.colorPickerState, (draft) => {
            Object.assign(draft, DEFAULT_COLOR_PICKER_STATE);
            draft.headerText = `${selectedPreviewItems.length} Selected`;
            draft.isOpen = true;
          }),
          type: ReducerActionTypes.SET_COLOR_PICKER_STATE,
        });
      }
    }
  }, [dispatchTableState, selectedPreviewItems, tableState.uiState.colorPickerState]);

  const handleConfirmColors = () => {
    dispatchTableState({
      payload: false,
      type: ReducerActionTypes.SET_COLOR_PICKER_IS_OPEN,
    });

    const { cellColor, textColor } = tableState.uiState.colorPickerState;

    handleSetCellColors({
      cellColor,
      textColor,
    });
  };

  const clearColor = () => {
    if (tableState.uiState.colorPickerState.colorMode === ColorMode.Cell) {
      dispatchTableState({
        payload: produce(tableState.uiState.colorPickerState, (draft) => {
          draft.cellColor = '';
        }),
        type: ReducerActionTypes.SET_COLOR_PICKER_STATE,
      });
    } else {
      dispatchTableState({
        payload: produce(tableState.uiState.colorPickerState, (draft) => {
          draft.textColor = '';
        }),
        type: ReducerActionTypes.SET_COLOR_PICKER_STATE,
      });
    }
  };

  const handleQuickColorPicker = (color: CellColor) => {
    handleSetCellColors(color as CellColors);
  };

  return (
    <Box>
      {tableState.uiState.colorPickerState.isOpen && (
        <ColorPickerDialog
          colorPickerState={tableState.uiState.colorPickerState}
          headerText={tableState.uiState.colorPickerState.headerText}
          isOpen={tableState.uiState.colorPickerState.isOpen}
          onClear={clearColor}
          onClose={() => dispatchTableState({ payload: false, type: ReducerActionTypes.SET_COLOR_PICKER_IS_OPEN })}
          onConfirm={handleConfirmColors}
          onDiscard={() => dispatchTableState({ payload: false, type: ReducerActionTypes.SET_COLOR_PICKER_IS_OPEN })}
          onStateChange={(colorPickerState) => dispatchTableState({
            payload: colorPickerState,
            type: ReducerActionTypes.SET_COLOR_PICKER_STATE,
          })}
        />
      )}
      <VStack align={'left'}>
        <Box>
          <HStack minW={`${DEFAULT_TABLE_WIDTH_MD}px`}>
            {isLoading ? (
              <VStack w={'100%'}>
                <Text textAlign={'center'}>{loadingMessage}</Text>
                <Spinner />
              </VStack>
            ) : (
              <HStack justifyContent={'space-between'} align={'top'} w={'100%'} gap={4}>
                <PaginatedTable
                  columns={availableItemsTableColumns}
                  columnFilters={availableItemsTableColumnFilters}
                  data={availableItemsTableData ?? []}
                  headerSortHandlers={headerSortHandlers}
                  TableHeader={availableItemsTableHeader}
                  TableHeaderButton={availableItemsTableHeaderButton}
                  tableInstanceRef={availableItemsTableInstanceRef}
                  columnVisibility={availableItemsTableColumnVisibility}
                  sortingState={availableItemsTableSortingState}
                  uniqueRowKey={availableRowUniqueKey}
                />

                <PaginatedTable
                  columnFilters={previewTableColumnFilters}
                  columns={previewTableColumns}
                  columnVisibility={previewTableColumnVisibility}
                  data={previewTableData ?? []}
                  draggableRows={previewRowsAreDraggable}
                  handleColorPicker={handleColorPicker}
                  handleQuickColorPicker={handleQuickColorPicker}
                  handleUpdateOrder={previewTableDragHandler}
                  headerSortHandlers={previewHeaderSortHandlers}
                  hideSortingTable={hideSortingTable}
                  noDataFoundMessage={previewTableNoDataFoundMessage}
                  overrideTableSorting={overrideTableSorting}
                  showColorPicker={showColorPicker}
                  showSideControls={showSideControls}
                  sortingFns={sortingFns}
                  sortingState={previewTableSortingState}
                  TableHeader={previewTableHeader}
                  TableHeaderButton={previewTableHeaderButton}
                  tableInstanceRef={previewItemsTableInstanceRef}
                  uniqueRowKey={previewRowUniqueKey}
                  uniqueSelectedIds={previewTableUniqueSelectedIds}
                />
              </HStack>
            )}
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default PaginatedPreviewTable;
