import { TOKEN_NAME } from '@/constants/defaults';

import { ConfigHelper, ConfigKeys } from './env';

enum LB_URL {
  Viewer = 'viewer',
  PublicViews = 'public',
}

export const lbBaseUrl = ConfigHelper.getConfig(ConfigKeys.LB_BASE_URL) ?? undefined;

// Unity url needed to authenticate when navigating to Viewer app
const buildUnityURL = () => {
  const token = localStorage.getItem(TOKEN_NAME);
  if (lbBaseUrl && token) {
    return `${lbBaseUrl}/login/unity?token=${token}`;
  }
};

// The url of the view in LB Lite
const buildViewerURL = (viewId: number | string) => {
  if (lbBaseUrl && viewId) {
    return `${lbBaseUrl}/${LB_URL.Viewer}/${viewId}`;
  }
};

// Returns the url that redirects to Unity for authentication and then to the view in Viewer app
export const getLBViewUrl = (viewId: number | string) => {
  const unityUrl = buildUnityURL();
  const viewerUrl = buildViewerURL(viewId);
  if (unityUrl && viewerUrl) {
    return `${unityUrl}&origin=${viewerUrl}`;
  }
};

// Returns the public url of the view in LB Lite
export const getLBPublicViewUrl = (publicId: number | string) => {
  if (lbBaseUrl && publicId) {
    return `${lbBaseUrl}/${LB_URL.PublicViews}/${publicId}`;
  }
};

// Opens url in a new tab
export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

// Opens the view in viewer app (in a new tab)
export const openViewInNewTab = (viewId: number | string) => {
  const viewUrl = getLBViewUrl(viewId);

  if (viewUrl) {
    openInNewTab(viewUrl);
  }
};

// Opens the public view (in a new tab)
export const openPublicViewInNewTab = (viewId: number | string) => {
  const viewUrl = getLBPublicViewUrl(viewId);
  if (viewUrl) {
    openInNewTab(viewUrl);
  }
};
