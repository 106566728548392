import React from 'react';

import FilterSelect, { FilterSelectValue } from '@/components/filter-select/FilterSelect';
import { DepartmentSlim } from '@/types/department.types';
import { DropdownAction } from '@/types/ui.types';

interface DepartmentsDropdownProps {
  departmentList: DepartmentSlim[];
  departmentChangeHandler?: (values: number[], action?: DropdownAction) => void;
  departmentListCloseHandler?: (values: number[]) => void;
  departmentListOpenHandler?: () => void;
  isLoading?: boolean;
  selectedIds?: number[];
}

const DepartmentsDropdown = (props: DepartmentsDropdownProps): React.JSX.Element => {
  const {
    departmentList,
    departmentChangeHandler,
    departmentListCloseHandler,
    departmentListOpenHandler,
    isLoading = false,
    selectedIds = [],
  } = props;

  const [selectedDepartments, setSelectedDepartments] = React.useState<number[]>(selectedIds);

  const handleDepartmentsChange = (values: FilterSelectValue[], action?: DropdownAction) => {
    const departmentIds = values.map(({ value }) => value);

    if (departmentChangeHandler) {
      return departmentChangeHandler(departmentIds, action);
    }

    setSelectedDepartments(departmentIds);
  };

  const handleDepartmentListClose = () => {
    if (departmentListCloseHandler) {
      return departmentListCloseHandler(selectedDepartments);
    }
  };

  return (
    <FilterSelect
      hasSelectAll={true}
      header={'Departments'}
      inputName={'departments'}
      isLoading={isLoading}
      isMultiSelect={true}
      onChangeHandler={(values, action) => handleDepartmentsChange(values as FilterSelectValue[], action)}
      onCloseHandler={handleDepartmentListClose}
      onOpenHandler={departmentListOpenHandler}
      optionLabelKey={'departmentName'}
      options={departmentList}
      optionValueKey={'departmentId'}
      placeHolderText={'Select Department(s)'}
      selectedValues={selectedIds ?? selectedDepartments}
    />
  );
};

export default DepartmentsDropdown;
