import { Box, HStack, Table as ChakraTable, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  SortingFn,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import classNames from 'classnames';
import React from 'react';
import { FaBars } from 'react-icons/fa';

import DraggableRow from '@/components/draggable-row/DraggableRow';
import PaginationControls from '@/components/pagination-controls/PaginationControls';
import SideControls from '@/components/side-controls/SideControls';
import {
  DEFAULT_ARRAY_START_INDEX,
  DEFAULT_MAX_WIDTH_TABLE_SIZE,
  DEFAULT_TABLE_HEIGHT_OFFSET,
  DEFAULT_TABLE_HEIGHT_VH,
  DEFAULT_TABLE_SIZE,
  DEFAULT_TABLE_WIDTH,
  EMPTY_ARRAY_LENGTH,
  paginationDefault,
} from '@/constants/defaults';
import { CellColor, HeaderSortHandler, HeaderSortHandlers } from '@/types/ui.types';
import { setsAreEqual } from '@/utils/arrays';
import SortingUtils from '@/utils/sorting';

import styles from './styles.module.scss';

// Offset to include/exclude necessary items when using array.slice
const SLICE_OFFSET = 2;

const TABLE_WIDTH_FACTOR = 0.96;

export interface PaginatedTableProps {
  // React-Table has typing issues, and I am not screwing around with it
  // disregard the terrible use of any here
  // https://github.com/TanStack/table/issues/4241
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<any, any>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  uniqueRowKey: string;
  columnFilters?: ColumnFiltersState;
  columnVisibility?: VisibilityState;
  debug?: boolean;
  draggableRows?: boolean;
  handleColorPicker?: () => void;
  toggleSortOverride?: () => void;
  handleQuickColorPicker?: (color: CellColor) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleUpdateOrder?: (updatedData: unknown[]) => void;
  hideSortingTable?: boolean;
  headerSortHandlers?: HeaderSortHandlers;
  noDataFoundMessage?: string;
  overrideTableSorting?: boolean;
  paginationIsZeroBased?: boolean;
  showColorPicker?: boolean;
  showSideControls?: boolean;
  sortingFns?: Record<string, SortingFn<unknown>>;
  sortingState?: SortingState;
  tableWidth?: string;
  tableHeight?: string;
  tableInstanceRef?: React.MutableRefObject<unknown>;
  uniqueSelectedIds?: Set<number | string>;
  TableHeader?: React.ReactNode;
  TableHeaderButton?: React.ReactNode;
}

const PaginatedTable = (props: PaginatedTableProps): React.JSX.Element => {
  const {
    columns,
    columnFilters = undefined,
    columnVisibility = undefined,
    data,
    draggableRows = false,
    handleColorPicker = () => void {},
    // TODO: Not used (keep/delete) What does this do?
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    toggleSortOverride = () => void {},
    handleQuickColorPicker = () => void {},
    handleUpdateOrder = () => void {},
    headerSortHandlers,
    hideSortingTable,
    noDataFoundMessage = 'No data found',
    overrideTableSorting = false,
    paginationIsZeroBased = true,
    showColorPicker = true,
    showSideControls = false,
    sortingFns = {},
    sortingState = [],
    TableHeader = null,
    TableHeaderButton = null,
    tableHeight = DEFAULT_TABLE_HEIGHT_VH,
    tableInstanceRef = undefined,
    tableWidth = `${DEFAULT_TABLE_WIDTH}px`,
    uniqueRowKey,
    uniqueSelectedIds = new Set<number>(),
  } = props;

  const [activeId, setActiveId] = React.useState<UniqueIdentifier>('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [activeItem, setActiveItem] = React.useState<Row<any>>();

  const tableInstance = useReactTable({
    autoResetAll: false,
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.id,
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: {
        pageIndex: paginationDefault.PageIndex,
        pageSize: paginationDefault.PageSize,
      },
    },
    manualSorting: overrideTableSorting,
    sortingFns,
    state: {
      columnFilters,
      columnVisibility,
      sorting: sortingState,
    },
  });

  const getRowFromDragId = (dragId: UniqueIdentifier) => {
    if (!dragId || !uniqueRowKey) return;

    return tableInstance.getRowModel().rows.find(({ original }) => original[uniqueRowKey] === dragId);
  };

  const reorderRows = React.useCallback(
    // Disabled eslint due to Row typing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (draggedRow: Row<any>, targetRow: Row<any>) => {
      let updatedData = [...data];
      const draggedRowIndex = draggedRow.index;
      const targetRowIndex = targetRow.index;
      const isActiveSelected = uniqueRowKey ? uniqueSelectedIds.has(draggedRow.original[uniqueRowKey]) : false;

      // If we only have one row selected, swap the rows
      // eslint-disable-next-line no-magic-numbers
      if (!uniqueSelectedIds.size || uniqueSelectedIds.size === 1 || !isActiveSelected) {
        // eslint-disable-next-line no-magic-numbers
        updatedData.splice(targetRowIndex, 0, updatedData.splice(draggedRowIndex, 1)[0] as typeof data);
      } else {
        // If we have multiple rows selected, insert the dragged row(s) above the target row
        // Disabled eslint due to Row typing
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const selectedRows: any[] = [];
        // Disabled eslint due to Row typing
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const unselectedRows: any[] = [];

        let dragDirection;

        const indexDifference = draggedRowIndex - targetRowIndex;
        const naturalLogOfIndexDifference = Math.log(indexDifference);

        // eslint-disable-next-line no-magic-numbers
        if (naturalLogOfIndexDifference >= 0) {
          dragDirection = 'up';
        } else if (naturalLogOfIndexDifference === -Infinity) {
          dragDirection = 'none';
        } else {
          dragDirection = 'down';
        }

        // If we are dragging down, we need to include the dragged row in the offset as it
        // will be removed from the array count. If we are dragging up, we do not need to
        // include the dragged row in the offset as it will not be added to the array count.
        // eslint-disable-next-line no-magic-numbers
        let numberOfSelectedRowsBetweenDraggedAndTarget = dragDirection === 'down' ? 1 : 0;

        updatedData.forEach((row, idx) => {
          const rowId = row[uniqueRowKey];

          if (uniqueSelectedIds.has(rowId)) {
            // Get rows that are selected and between the dragged row and the target row
            if (rowId !== draggedRow.original[uniqueRowKey] && idx < targetRowIndex) {
              ++numberOfSelectedRowsBetweenDraggedAndTarget;
            }
            selectedRows.push(row);
          } else {
            unselectedRows.push(row);
          }
        });

        /*
         * If we are dragging up, the unselected items will be a contiguous block of rows
         * and therefore we do not need an offset
         * */
        // let indexOffset = targetRowIndex;

        const indexOffset =
          // eslint-disable-next-line no-magic-numbers
          targetRowIndex - numberOfSelectedRowsBetweenDraggedAndTarget < 0
            ? // eslint-disable-next-line no-magic-numbers
            0
            : targetRowIndex - numberOfSelectedRowsBetweenDraggedAndTarget;
        /*
         * If we are dragging down, the unselected items will NOT be a contiguous block of rows
         * and we will need to account for the number of selected rows being removed from the list
         * which will cause an index offset.
         * */

        // if (dragDirection === 'down') {
        //   // eslint-disable-next-line no-magic-numbers
        //   // indexOffset = targetRowIndex - selectedRows.length < 0 ? 0 : targetRowIndex - selectedRows.length;
        //   indexOffset =
        //     targetRowIndex - numberOfSelectedRowsBetweenDraggedAndTarget < 0
        //       ? 0
        //       : targetRowIndex - numberOfSelectedRowsBetweenDraggedAndTarget;
        // }

        // Ensure we do not go out of bounds of the array
        // eslint-disable-next-line no-magic-numbers
        const upperBoundIndex = indexOffset + 1 > unselectedRows.length ? unselectedRows.length : indexOffset + 1;

        // Slice the rows above the target row. Keep in mind that Array.slice is non-inclusive of the upper bound
        // eslint-disable-next-line no-magic-numbers
        const rowsAboveTarget = unselectedRows.slice(0, indexOffset);
        // Get the target row (the row which we are dragging over)
        const targetRow = unselectedRows[indexOffset];
        // Slice the rest of the rows off the array
        const rowsBelowTarget = unselectedRows.slice(upperBoundIndex);

        // If the drag direction is up, we want to insert the selected rows above the target row
        if (dragDirection === 'up') {
          updatedData = [...rowsAboveTarget, ...selectedRows, targetRow, ...rowsBelowTarget];
        }
        // If the drag direction is down, we want to insert the selected rows below the target row
        else if (dragDirection === 'down') {
          updatedData = [...rowsAboveTarget, targetRow, ...selectedRows, ...rowsBelowTarget];
        }
      }

      handleUpdateOrder(updatedData);
    },
    [data, uniqueRowKey, uniqueSelectedIds, handleUpdateOrder],
  );

  React.useEffect(() => {
    if (tableInstanceRef) {
      tableInstanceRef.current = tableInstance;
    }
  });

  // ToDo: Is this really needed?
  // This is needed to save the new order when the user re-orders items via the header sorting functionality
  // React.useEffect(() => {
  //   console.log('Handle draggable rows');
  //
  //   // Update order only for tables that have draggable rows
  //   if (draggableRows && sortingState?.length) {
  //     const sortedRows = tableInstance.getSortedRowModel().rows.map((item) => item.original) ?? [];
  //
  //     handleUpdateOrder(sortedRows);
  //   }
  // }, [draggableRows, sortingState?.length, handleUpdateOrder, tableInstance]);

  // eslint-disable-next-line no-magic-numbers
  const noTableRows = !columns.length || tableInstance.getRowModel().rows.length === 0;

  const sortedIds = React.useMemo(() => data?.map((item) => ({ id: item[uniqueRowKey] })), [data, uniqueRowKey]);

  const getStaticRow = (row: Row<unknown>) => {
    return (
      <Tr>
        <Td padding={0} paddingLeft={5}>
          <FaBars opacity={0.6} />
        </Td>
        {row.getVisibleCells().map((cell) => {
          return (
            <Td padding={0} key={cell.id} colSpan={row.getVisibleCells().length} className={classNames(styles.text)}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          );
        })}
      </Tr>
    );
  };

  const getTableBody = () => {
    const tableRows = tableInstance.getRowModel().rows.map((row) => {
      return (
        <Tr key={row.id} w={'100%'} display={'table'}>
          {row.getVisibleCells().map((cell) => {
            return (
              <Td padding={0} key={cell.id} className={classNames(styles.text)}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            );
          })}
        </Tr>
      );
    });

    if (draggableRows) {
      return (
        <SortableContext items={sortedIds} strategy={verticalListSortingStrategy} id={uniqueRowKey}>
          {tableInstance.getRowModel().rows.map((row) => {
            const isSelected = uniqueSelectedIds.has(row.original[uniqueRowKey]);
            const isRowActive = activeId === row.original[uniqueRowKey];
            const isRowHidden = !!(
              activeId &&
              activeItem &&
              !isRowActive &&
              isSelected &&
              uniqueSelectedIds.has(activeItem.original[uniqueRowKey])
            );

            return (
              <DraggableRow
                key={row.id}
                row={row}
                isHidden={isRowHidden}
                isActive={isRowActive}
                isSelected={isSelected}
                rowUniqueKey={uniqueRowKey}
              />
            );
          })}
        </SortableContext>
      );
    }

    return tableRows;
  };

  const sensors = useSensors(useSensor(PointerSensor));
  const resetDrag = () => {

    setActiveId('');
    setActiveItem(undefined);
    document.body.style.setProperty('cursor', '');
  };

  // ToDo: Pass key name as prop
  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId);
    setActiveItem(getRowFromDragId(activeId));

    // Reset table sorting (asc/desc) to avoid blocking drag and drop re-ordering functionality

    document.body.style.setProperty('cursor', 'grabbing');
  }

  const handleDragEnd = ({ over }: DragEndEvent) => {
    const activeRow = activeItem;
    const overRow = getRowFromDragId(over?.id ?? '');

    if (!activeRow || !overRow) return;

    reorderRows(activeRow, overRow);
    resetDrag();
  };

  const handleDragCancel = () => {
    resetDrag();
  };

  const currentPageIndex = tableInstance.getState().pagination.pageIndex;
  const getLastPageIndex = React.useCallback(() => {
    if (!columns.length) {
      // eslint-disable-next-line no-magic-numbers
      return 0;
    }

    // eslint-disable-next-line no-magic-numbers
    return paginationIsZeroBased ? tableInstance.getPageCount() - 1 : tableInstance.getPageCount();
  }, [columns.length, paginationIsZeroBased, tableInstance]);

  const pageSize = tableInstance.getState().pagination.pageSize;

  // --- Side Buttons handlers Start
  const handleMoveItemsTop = React.useCallback(() => {
    // Reset table sorting so the custom ordering does not get overwritten by the asc/desc table sorting functionality
    const items = [...data];
    const selectedItems = items.filter((item) => uniqueSelectedIds.has(item[uniqueRowKey]));

    if (items.length > EMPTY_ARRAY_LENGTH && selectedItems.length > EMPTY_ARRAY_LENGTH) {
      // Ids of the items that are on the top of the list
      const topItemsIds = new Set(
        items.slice(DEFAULT_ARRAY_START_INDEX, selectedItems.length).map((item) => item[uniqueRowKey]),
      );

      // Items are already on top if the items Ids that are on top of the list are equal with the selected items Ids
      const itemsAlreadyOnTop = setsAreEqual(topItemsIds, uniqueSelectedIds);
      // Do not re-order in case the items are already on top
      if (itemsAlreadyOnTop) {
        return;
      }

      // Arrange the selected items as the first items on the array and then the remaining items
      const newData = [...selectedItems, ...items.filter((item) => !uniqueSelectedIds.has(item[uniqueRowKey]))];

      handleUpdateOrder(newData);

      // Move to the first page
      if (currentPageIndex !== paginationDefault.PageIndex) {
        tableInstance.setPageIndex(paginationDefault.PageIndex);
      }
    }
  }, [data, uniqueSelectedIds, uniqueRowKey, handleUpdateOrder, currentPageIndex, tableInstance]);

  const handleMoveItemsUp = React.useCallback(() => {

    // Reset table sorting so the custom ordering does not get overwritten by the asc/desc table sorting functionality

    const items = [...data];
    const selectedItems = items.filter((item) => uniqueSelectedIds.has(item[uniqueRowKey]));

    if (items.length > EMPTY_ARRAY_LENGTH && selectedItems.length > EMPTY_ARRAY_LENGTH) {
      // The position of the first selected item on the list
      const positionToSlice = items.findIndex(
        (item) => item[uniqueRowKey] === selectedItems[DEFAULT_ARRAY_START_INDEX][uniqueRowKey],
      );

      // Move items to the top if the selection includes any of the first two items on the list
      // eslint-disable-next-line no-magic-numbers
      if (positionToSlice <= 1) {
        handleMoveItemsTop();
        return;
      }

      // Items that will not move
      // eslint-disable-next-line no-magic-numbers
      const firstPart = items.slice(DEFAULT_ARRAY_START_INDEX, positionToSlice - 1);

      // Items that will be shifted down (remove the selected items)
      // eslint-disable-next-line no-magic-numbers
      const lastPart = items.slice(positionToSlice - 1).filter((item) => !uniqueSelectedIds.has(item[uniqueRowKey]));

      // Arrange the items
      const newData = [...firstPart, ...selectedItems, ...lastPart];

      // Index if the first item of the page
      // eslint-disable-next-line no-magic-numbers
      const minPageIndex = paginationIsZeroBased ? pageSize * currentPageIndex : pageSize * (currentPageIndex - 1);

      // Go to previous page if the selection contains the first item of that page
      if (positionToSlice <= minPageIndex) {
        tableInstance.previousPage();
      }

      handleUpdateOrder(newData);
    }
  }, [data, uniqueSelectedIds, uniqueRowKey, paginationIsZeroBased, pageSize, currentPageIndex, handleUpdateOrder, handleMoveItemsTop, tableInstance]);

  const handleMoveItemsBottom = React.useCallback(() => {
    // Reset table sorting so the custom ordering does not get overwritten by the asc/desc table sorting functionality

    const items = [...data];
    const selectedItems = items.filter((item) => uniqueSelectedIds.has(item[uniqueRowKey]));

    if (items.length > EMPTY_ARRAY_LENGTH && selectedItems.length > EMPTY_ARRAY_LENGTH) {
      // Ids of the items that are on the bottom of the list
      const bottomItemsIds = new Set(
        items
          .slice(Math.max(items.length - selectedItems.length, DEFAULT_ARRAY_START_INDEX))
          .map((item) => item[uniqueRowKey]),
      );

      // Items are already on the bottom if the items Ids that are on the bottom of the list are equal with the selected items Ids
      const itemsAlreadyOnBottom = setsAreEqual(bottomItemsIds, uniqueSelectedIds);
      // Do not re-order in case the items are already on the bottom
      if (itemsAlreadyOnBottom) {
        return;
      }

      // Arrange the remaining items as the last items on the array and then the selected items
      const newData = [...items.filter((item) => !uniqueSelectedIds.has(item[uniqueRowKey])), ...selectedItems];

      handleUpdateOrder(newData);

      const lastPageIndex = getLastPageIndex();

      // Move to the last page
      if (currentPageIndex !== lastPageIndex) {
        tableInstance.setPageIndex(lastPageIndex);
      }
    }
  }, [data, uniqueSelectedIds, uniqueRowKey, handleUpdateOrder, getLastPageIndex, currentPageIndex, tableInstance]);

  const handleMoveItemsDown = React.useCallback(() => {

    // Reset table sorting so the custom ordering does not get overwritten by the asc/desc table sorting functionality

    const items = [...data];

    const selectedItems = items.filter((item) => uniqueSelectedIds.has(item[uniqueRowKey]));

    if (items.length > EMPTY_ARRAY_LENGTH && selectedItems.length > EMPTY_ARRAY_LENGTH) {
      // The position of the last selected item on the list
      const positionToSlice = items.findIndex(
        // eslint-disable-next-line no-magic-numbers
        (item) => item[uniqueRowKey] === selectedItems[selectedItems.length - 1][uniqueRowKey],
      );

      // Move items to the bottom if the selection includes any of last two items on the list

      if (positionToSlice >= items.length - SLICE_OFFSET) {
        handleMoveItemsBottom();
        return;
      }

      // Items that will be shifted up (remove the selected items)
      // Add 2 to include the last element (as slice is exclusive to the end parameter)
      const firstPart = items

        .slice(DEFAULT_ARRAY_START_INDEX, positionToSlice + SLICE_OFFSET)
        .filter((item) => !uniqueSelectedIds.has(item[uniqueRowKey]));

      // Items that will be shifted down
      // Add 2 to exclude the first element (it is included on the firstPart to be moved up)

      const lastPart = items.slice(positionToSlice + SLICE_OFFSET);

      // Arrange the items
      const newData = [...firstPart, ...selectedItems, ...lastPart];

      // Index if the last first item of the page
      const maxPageIndex = paginationIsZeroBased
        ? // eslint-disable-next-line no-magic-numbers
        (currentPageIndex + 1) * pageSize - 1
        : // eslint-disable-next-line no-magic-numbers
        currentPageIndex * pageSize - 1;

      // Go to next page if the selection contains the last item of that page
      if (positionToSlice >= maxPageIndex) {
        tableInstance.nextPage();
      }

      handleUpdateOrder(newData);
    }
  }, [data, uniqueSelectedIds, uniqueRowKey, paginationIsZeroBased, currentPageIndex, pageSize, handleUpdateOrder, handleMoveItemsBottom, tableInstance]);
  // --- Side Buttons handlers End

  // Color picker is disabled if there are no selected items
  const noSelectedItems = uniqueSelectedIds.size === EMPTY_ARRAY_LENGTH;

  // Sorting buttons are disabled if there are no selected items or the user is not sorting  by Custom Order / Time then custom order (rows are not draggable)
  const sortingDisabled = noSelectedItems || !draggableRows;

  return (
    <HStack gap={0} alignItems="flex-start" width="100%">
      <Box borderWidth="1px" borderRadius="lg" p={2}>
        <Box className={styles.container}>
          {TableHeader}

          <Box mt={4} p={1}>
            {!columns.length ? <></> :
              <PaginationControls
                canNextPage={tableInstance.getCanNextPage()}
                canPreviousPage={tableInstance.getCanPreviousPage()}
                goToPage={tableInstance.setPageIndex}
                isZeroBased={paginationIsZeroBased}
                nextPage={tableInstance.nextPage}
                pageCount={tableInstance.getPageCount()}
                pageIndex={tableInstance.getState().pagination.pageIndex}
                pageSize={tableInstance.getState().pagination.pageSize}
                previousPage={tableInstance.previousPage}
                setPageSize={tableInstance.setPageSize}
                width={tableWidth}
              />}
          </Box>
        </Box>
        <TableContainer
          minH={`${DEFAULT_TABLE_SIZE + DEFAULT_TABLE_HEIGHT_OFFSET}px`}
          maxH={tableHeight}
          overflowX={'hidden'}
          minW={tableWidth}
          maxWidth={`${DEFAULT_MAX_WIDTH_TABLE_SIZE}px`}
          w={'100%'}
          className={styles.table}
        >
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
            modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
          >
            <ChakraTable variant={'striped'}>
              <Thead
                top={'0px'}
                position={'sticky'}
                borderBottom={'2px solid #DADDDE'}
                cursor={hideSortingTable ? 'not-allowed' : 'pointer'}
                display={'table-caption'}
              >
                {tableInstance.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id} w={'100%'} display={'flex'} alignItems={'center'}>
                    {headerGroup.headers.map((header) => {
                      const headerSortDirection = tableInstance
                        .getState()
                        .sorting.find((s) => s.id === header.id)?.desc;
                      const headerSortHandler = headerSortHandlers?.find(
                        (h: HeaderSortHandler) => h.columnId === header.column.id,
                      )?.sortHandler;

                      const canSort = header.column.getCanSort();
                      const nextSorting = header.column.getNextSortingOrder();
                      const isSorted = header.column.getIsSorted();

                      return (
                        <Th key={header.id}>
                          <HStack
                            h={'32px'}
                            maxW={'500px'}
                            style={{ pointerEvents: hideSortingTable ? 'none' : 'unset' }}
                            color={hideSortingTable ? '#afafaf' : 'gray.600'}
                            onClick={headerSortHandler}
                            title={SortingUtils.getSortingTitle(canSort, nextSorting)}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {SortingUtils.getSortingIcon(isSorted, headerSortDirection)}
                          </HStack>
                        </Th>
                      );
                    })}
                    {TableHeaderButton}
                  </Tr>
                ))}
              </Thead>
              <Tbody
                height={`${DEFAULT_TABLE_SIZE}px`}
                overflowY={'auto'}
                maxWidth={`${DEFAULT_MAX_WIDTH_TABLE_SIZE}px`}
                w={'100%'}
                display={'block'}
              >
                {!columns.length || noTableRows ? (
                  <Tr width="100%" display={'table'} key={noDataFoundMessage} textAlign={'center'}>
                    <Td textAlign={'center'} width="100%">
                      {noDataFoundMessage}
                    </Td>
                  </Tr>
                ) : (
                  getTableBody()
                )}
              </Tbody>
            </ChakraTable>
            <DragOverlay>
              {activeId && activeItem && (
                // Width scaling for overlay is a bit off, so we need to multiply by 0.96 to prevent overlap with scrollbar
                <ChakraTable variant={'striped'} w={Number.parseInt(tableWidth) * TABLE_WIDTH_FACTOR}>
                  <Tbody h={'40px'}>{getStaticRow(activeItem)}</Tbody>
                </ChakraTable>
              )}
            </DragOverlay>
          </DndContext>
        </TableContainer>
      </Box>
      {showSideControls && (
        <SideControls
          showColorPicker={showColorPicker}
          colorPickerDisabled={noSelectedItems}
          sortingDisabled={sortingDisabled}
          onColorPickerClick={handleColorPicker}
          onQuickColorPickerClick={handleQuickColorPicker}
          onMoveTopClick={handleMoveItemsTop}
          onMoveUpClick={handleMoveItemsUp}
          onMoveDownClick={handleMoveItemsDown}
          onMoveBottomClick={handleMoveItemsBottom}
        />
      )}
    </HStack>
  );
};

export default PaginatedTable;
