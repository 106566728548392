import React from 'react';

import FilterSelect, { FilterSelectValue } from '@/components/filter-select/FilterSelect';

interface PersonnelTypeDropdownProps {
  availablePersonnelTypes: FilterSelectValue[];
  selectedPersonnelTypeIds: number[];
  /** If set to true, the selection component will display a loading indicator until set to false */
  isLoading?: boolean;
  onChangeHandler?: (values: number[]) => void;
  personnelTypeListCloseHandler?: (values: number[]) => void;
  personnelTypeListOpenHandler?: () => void;
}

const PersonnelTypeDropdown = (props: PersonnelTypeDropdownProps): JSX.Element => {
  const {
    availablePersonnelTypes,
    selectedPersonnelTypeIds,
    isLoading,
    onChangeHandler,
    personnelTypeListCloseHandler,
    personnelTypeListOpenHandler,
  } = props;

  const changeHandler = (values: unknown) => {
    const selectedPersonnelTypeIds = (values as FilterSelectValue[]).map(({ value }) => value);

    if (onChangeHandler) return onChangeHandler(selectedPersonnelTypeIds);

    // ToDo: Dispatch setSelectedPersonnelTypeIds action
    // setSelectedPersonnelTypeIds(departmentIds);
  };

  const handlePersonnelTypeListClose = () => {
    if (personnelTypeListCloseHandler) {
      return personnelTypeListCloseHandler(selectedPersonnelTypeIds);
    }
  };

  return (
    <FilterSelect
      hasSelectAll={true}
      inputName={'personnelType'}
      isLoading={isLoading}
      isMultiSelect={true}
      onChangeHandler={changeHandler}
      onCloseHandler={handlePersonnelTypeListClose}
      onOpenHandler={personnelTypeListOpenHandler}
      header={'Personnel Types'}
      options={availablePersonnelTypes}
      optionLabelKey={'label'}
      optionValueKey={'value'}
      placeHolderText={'Select Personnel Type(s)'}
      selectedValues={selectedPersonnelTypeIds}
    />
  );
};

export default PersonnelTypeDropdown;
