/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, ButtonGroup } from '@chakra-ui/react';
import { ColumnDef, ColumnSort, createColumnHelper, SortingState } from '@tanstack/react-table';
import React from 'react';

import { useGetPersonnelQuery } from '@/API/personnel.api';
import PaginatedTableControlled from '@/components/paginated-table-controlled/PaginatedTableControlled';
import PaginatedTableSkeleton from '@/components/skeletons/paginated-table-skeleton/PaginatedTableSkeleton';
import { paginationDefault } from '@/constants/defaults';
import { SORTING_ORDER } from '@/constants/ui';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { initializePersonnelMembershipDrawer } from '@/store/slices/personnelMembershipDrawer.slice';
import { PersonnelListItem } from '@/types/personnel.types';

const DEFAULT_SORTING: SortingState = [
  {
    desc: false,
    id: 'name',
  },
];

const PersonnelListView = (): React.JSX.Element => {
  const {
    isDepartmentListOpen,
    isHideInactiveToggled,
    personnelSearchFilterValue,
    selectedDepartmentIds,
  } = useAppSelector((state) => state.personnelPage);

  const dispatch = useAppDispatch();

  const [pageNumber, setPageNumber] = React.useState(paginationDefault.PageNumber);
  const [pageSize, setPageSize] = React.useState(paginationDefault.PageSize);
  // ToDO: Extract sorting state to a custom hook
  const [sortingState, setSortingState] = React.useState<SortingState>(DEFAULT_SORTING);

  const getSortingStateById = (columnId: string): ColumnSort | undefined =>
    sortingState.find((col) => col.id === columnId);

  const setSortingStateById = (columnId: string, desc: boolean | undefined): void => {
    // Sorting for only one column at a time
    const sorting = desc !== undefined ? [{ desc, id: columnId }] : [];

    setSortingState(sorting);
  };

  const getSortingOrder = (currentValue: boolean | undefined): SORTING_ORDER => {
    if (currentValue === undefined) {
      return SORTING_ORDER.NONE;
    }

    return currentValue ? SORTING_ORDER.DESC : SORTING_ORDER.ASC;
  };

  const getPersonnelSortParam = (): string => {
    const personnelNameSort = getSortingOrder(getSortingStateById('name')?.desc);
    const personnelDisplayNameSort = getSortingOrder(getSortingStateById('display')?.desc);

    if (personnelNameSort !== SORTING_ORDER.NONE) {
      return `[Name:${personnelNameSort}]`;
    }

    if (personnelDisplayNameSort !== SORTING_ORDER.NONE) {
      return `[DisplayName:${personnelDisplayNameSort}]`;
    }

    return '';
  };

  const {
    pagination,
    personnelList,
    isLoading: personnelIsLoading,
    isFetching: personnelIsFetching,
  } = useGetPersonnelQuery(
    {
      // eslint-disable-next-line camelcase
      dept_list: selectedDepartmentIds.join('~'),
      // if Hide Inactive toggle is OFF, do not send expired field
      expired: isHideInactiveToggled ? false : undefined,
      filters: personnelSearchFilterValue ? `[name*${personnelSearchFilterValue}]` : '',
      hideInactive: isHideInactiveToggled,
      pageNumber,
      pageSize,
      sort: getPersonnelSortParam(),
    },
    {
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        isFetching,
        isLoading,
        pagination: data?.pagination ?? paginationDefault,
        personnelList: data?.personnelList ?? [],
      }),
      skip: isDepartmentListOpen,
    },
  );

  const [pageIndex, setPageIndex] = React.useState(paginationDefault.PageIndex);

  React.useEffect(() => {
    // eslint-disable-next-line no-magic-numbers
    setPageIndex(pageNumber - 1);
  }, [pageNumber, pagination.PageNumber]);

  React.useEffect(() => {
    setPageIndex(paginationDefault.PageIndex);
    setPageNumber(paginationDefault.PageNumber);
  }, [pageSize, selectedDepartmentIds]);

  const handleGoToPage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };
  const handleNextPage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handlePreviousPage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handleSetPageSize = (pageSize: number) => {
    setPageNumber(paginationDefault.PageNumber);
    setPageSize(pageSize);
  };

  const getRowControls = (personnel: PersonnelListItem) => {
    return (
      <ButtonGroup>
        <Button colorScheme={'blue'} variant={'ghost'}
                onClick={() => dispatch(initializePersonnelMembershipDrawer(personnel.id))}>
          Edit Access
        </Button>
      </ButtonGroup>
    );
  };

  const columnHelper = createColumnHelper<PersonnelListItem>();

  const columns: ColumnDef<PersonnelListItem, string>[] = [
    columnHelper.accessor(({ name }) => `${name.last}, ${name.first}`, {
      cell: (info) => info.getValue(),
      header: () => <Box>Personnel</Box>,
      id: 'name',
    }),
    columnHelper.accessor(({ name }) => name.display, {
      cell: (info) => info.getValue(),
      header: () => <Box>Display Name</Box>,
      id: 'display',
    }),
    columnHelper.display({
      cell: (info) => getRowControls(info.row.original),
      id: 'rowControls',
    }),
  ];

  const getSortingState = (column: string) => {
    const currentSorting = sortingState.find((item) => item.id === column)?.desc;

    // If no sorting, set sorting to ascending (desc: false)
    if (currentSorting === undefined) {
      return false;
    }

    // If sorting is descending, set sorting to undefined (remove sorting)
    if (currentSorting) {
      return undefined;
    }

    // If sorting is ascending, set sorting to descending (desc: true)
    return true;
  };

  const getIsLoading = (): boolean => {
    return personnelIsLoading || personnelIsFetching;
  };

  if (getIsLoading()) {
    return <PaginatedTableSkeleton />;
  }

  return (
    <PaginatedTableControlled
      columns={columns}
      data={personnelList}
      filters={[]}
      headerSortHandlers={[
        {
          columnId: 'name',
          sortHandler: () => setSortingStateById('name', getSortingState('name')),
        },
        {
          columnId: 'display',
          sortHandler: () => setSortingStateById('display', getSortingState('display')),
        },
      ]}
      isLoading={getIsLoading()}
      manualSorting={true}
      onGoToPage={handleGoToPage}
      onNextPage={handleNextPage}
      onPreviousPage={handlePreviousPage}
      onSetPageSize={handleSetPageSize}
      overridePaginationModel={true}
      pagination={{ ...pagination, PageIndex: pageIndex }}
      sortingState={sortingState}
    />
  );
};

export default PersonnelListView;
