import { ColumnFiltersState } from '@tanstack/react-table';
import { produce } from 'immer';

export const updateColumnFilter = (id: string, value: unknown, columnState: ColumnFiltersState) => {

  return produce(columnState, (draft) => {
    const filterIdx = draft.findIndex((filter) => filter.id === id);

    // eslint-disable-next-line no-magic-numbers
    if (filterIdx === -1) {
      draft.push({
        id,
        value,
      });
    } else {
      if (!value) {
        // eslint-disable-next-line no-magic-numbers
        draft.splice(filterIdx, 1);
        return;
      }
      draft[filterIdx] = {
        id,
        value,
      };
    }
  });
};
